:root {
	--main-main-color: hsl(85, 35%, 58%);
	--dark-main-color: hsl(85, 35%, 38%);
	--light-color: hsl(0, 0%, 98%);
}

.ant-btn-primary {
	background-color: var(--main-main-color);
	border-color: var(--main-main-color);
	color: var(--light-color);
}

.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
	background-color: var(--dark-main-color);
	border-color: var(--dark-main-color);
	color: var(--light-color) !important;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
	border-color: var(--dark-main-color);
	color: var(--dark-main-color);
}
